<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h2>
                Utilisateur
                <small class="text-kalkin-2">
                    <b-icon-person-circle />
                    {{ user.firstName }} {{ user.lastName }}
                </small>
            </h2>
            <div>
                <b-button
                    class="mr-2"
                    size="sm"
                    variant="outline-secondary"
                    @click="goEditUser"
                    v-if="me.credentials >= user.credentials"
                >
                    <b-icon-pencil /> Modifier l'utilisateur
                </b-button>
                <b-button
                    size="sm"
                    variant="outline-danger"
                    v-b-modal.ModalDanger
                    v-if="me.credentials >= 10"
                >
                    <b-icon-trash /> Supprimer l'utilisateur
                </b-button>
            </div>
        </div>

        <div class="k-page-body text-left">
            <div class="k-panel-2">
                <h3>Informations</h3>
                <div>
                    Email : <span class="text-kalkin-2">{{ user.mail }}</span>
                </div>
                <div>
                    Téléphone :
                    <span class="text-kalkin-2">
                        {{ user.phone || "Non renseigné" }}
                    </span>
                </div>

                <div>
                    Niveau de droits :
                    <span class="text-kalkin-2">
                        {{ user.credentials | userRoleFromCreds }}
                    </span>
                </div>
                <div>
                    Ajouté le
                    <span class="text-kalkin-2">
                        {{ user.createdAt | dateFromISO }}
                    </span>
                </div>
                <div v-if="user.connectedAt">
                    Dernière connexion le
                    <span class="text-kalkin-2">
                        {{ user.connectedAt | dateFromISO }} (
                        {{ user.connectedAt | daysSinceISO }} )
                    </span>
                </div>
                <div v-else class="text-danger">Jamais connecté</div>
            </div>

            <div class="k-panel-2">
                <h3>Projets de l'utilisateur</h3>
                <TableUserProjects :user-id="userId" />
            </div>
        </div>

        <ModalDanger
            message="Voulez-vous vraiment supprimer cet utilisateur?"
            @confirmed="deleteUser"
        />
    </div>
</template>
<script>
import ModalDanger from "@/components/modal/ModalDanger";
import TableUserProjects from "@/components/model/user/TableUserProjects";
export default {
    components: {
        TableUserProjects,
        ModalDanger,
    },

    data: function() {
        return {
            me: this.$store.state.user.user,
            userId: this.$route.params.userId,
            user: {},
        };
    },

    methods: {
        async fetchUser() {
            this.user = await this.$store.dispatch("user/getUser", {
                id: this.userId,
            });
            if (!this.user) {
                this.$router.push({ name: "NotFound" });
            }
        },

        async deleteUser() {
            await this.$store.dispatch("user/deleteUser", {
                id: this.user.id,
            });
            this.$router.push({ name: "AdminListUsers" });
        },

        goEditUser: function() {
            this.$router.push({
                name: "AdminEditUser",
                params: { user: this.user },
            });
        },
    },

    async beforeMount() {
        await this.fetchUser();
    },
};
</script>
